<template>
	<div class="diy_list page_room_management" id="room_management_list">
		<div class="warp">
			<div class="container">
				<div class="row">
					<div class="col">
						<span class="title">房间管理列表</span>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 搜索栏 -->
						<div class="view">
							<b-form-input size="sm" class="mr-sm-2" placeholder="房号搜索" v-model="query['room_number']" />
							<b-form-input size="sm" class="mr-sm-2" placeholder="类型搜索" v-model="query['type']" />
							<b-form-input size="sm" class="mr-sm-2" placeholder="使用状态搜索" v-model="query['use_status']" />
							<b-button size="sm" @click="search()" >
								<b-icon icon="search"/>
							</b-button>
						</div>
						<!-- /搜索栏 -->
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 筛选 -->
						<div class="view">
							<b-dropdown :text="title" variant="outline-dark" left>
							    <b-dropdown-item v-for="(o,i) in list_type" @click="filter_set(o,'type')" >
									{{ o }}
							    </b-dropdown-item>
							</b-dropdown>
							<b-dropdown :text="title" variant="outline-dark" left>
							    <b-dropdown-item v-for="(o,i) in list_use_status" @click="filter_set(o,'use_status')" >
									{{ o }}
							    </b-dropdown-item>
							</b-dropdown>
						</div>
						<!-- /筛选 -->
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 排序 -->
						<div class="view">
							<b-dropdown text="排序" variant="outline-dark" left>
							    <b-dropdown-item v-for="(o, i) in list_sort" :key="i" @click="set_sort(o)" >
							        {{ o.name }}
							    </b-dropdown-item>
							</b-dropdown>
						</div>
						<!--/排序 -->
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 列表 -->
						<list_room_management :list="list" />
						<!-- /列表 -->
					</div>
				</div>
				<div class="row">
					<div class="col overflow-auto flex_cc">
						<!-- 分页器 -->
						<diy_pager v-model="query['page']" :size="query['size']" :count="count" v-on:toPage="toPage" v-on:toSize="toSize" ></diy_pager>
						<!-- /分页器 -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import list_room_management from "@/components/diy/list_room_management.vue";
	import diy_pager from "@/components/diy/diy_pager";
	import mixin from "@/mixins/page.js";

	export default {
		mixins: [mixin],
		components: {
			diy_pager,
			list_room_management
		},
		data() {
			return {
				url_get_list: "~/api/room_management/get_list?like=0",

				// 查询条件
				query: {
					keyword: "",
					page: 1,
					size: 10,
					"room_number": "", // 房号
					"type": "", // 类型
					"use_status": "", // 使用状态
				},

				// 排序内容
				list_sort: [{
						name: "创建时间从高到低",
						value: "`create_time` desc",
					},
					{
						name: "创建时间从低到高",
						value: "`create_time` asc",
					},
					{
						name: "更新时间从高到低",
						value: "`update_time` desc",
					},
					{
						name: "更新时间从低到高",
						value: "`update_time` asc",
					},
					{
						name: "房号正序",
						value: "`room_number` asc",
					},
					{
						name: "房号倒序",
						value: "`room_number` desc",
					},
					{
						name: "类型正序",
						value: "`type` asc",
					},
					{
						name: "类型倒序",
						value: "`type` desc",
					},
					{
						name: "使用状态正序",
						value: "`use_status` asc",
					},
					{
						name: "使用状态倒序",
						value: "`use_status` desc",
					},
				],
				// 类型列表
				"list_type": ['单人','双人'],
				// 使用状态列表
				"list_use_status": ['空闲','使用中','维修'],
			}
		},
		methods: {
			/**
			 * 筛选选择
			 */
			filter_set(o,key) {
			    if (o == "全部") {
			        this.query[key] = "";
			    } else {
			        this.query[key] = o;
			    }
			    this.search();
			},

			/**
			 * 排序
			 */
			set_sort(o) {
			    this.query.orderby = o.value;
			    this.search();
			},

			/**
			 * 筛选
			 */
			filter_type(o) {
				if (o == "全部") {
					this.query["type"] = "";
				} else {
					this.query["type"] = o;
				}
				this.search();
			},
			filter_use_status(o) {
				if (o == "全部") {
					this.query["use_status"] = "";
				} else {
					this.query["use_status"] = o;
				}
				this.search();
			},

			/**
			 * 重置
			 */
			reset() {
				this.query.room_number = ""
				this.query.type = ""
				this.query.use_status = ""
				this.search();
			},

			// 返回条数
			toSize(i){
				this.query.size = i;
				this.first();
			},

			// 返回页数
			toPage(i){
				this.query.page = i;
				this.first();
			}
		},
		computed: {
		},
		created() {
		}
	}
</script>

<style>
</style>
